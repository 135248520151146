import {isBrowser} from '@halp/util';

export const COMMIT_HASH = process.env.COMMIT_HASH ?? '';

export const BUGSNAG_PUBLIC =
	process.env.BUGSNAG_PUBLIC ?? 'd50885b1e8c4b94ee7dba4256d62025a';

export const SEGMENT_PUBLIC =
	process.env.SEGMENT_PUBLIC ?? 'MdXmWPXc8Bn5WL3k6zyydPjCCIZhPTRx';

export const GTAG_PUBLIC =
	process.env.GTAG_PUBLIC ??
	(process.env.NODE_ENV === 'development' ? 'G-0000000000' : 'G-MTLGWL2WW7');

export const ADWORDS_PUBLIC =
	process.env.ADWORDS_PUBLIC ??
	(process.env.NODE_ENV === 'development' ? 'AW-000000000' : 'AW-728585372');

export const HOST =
	process?.env.NODE_ENV === 'development' ? 'halp.localhost:4000' : 'halp.co';

export const PROTOCOL = isBrowser() ? window.location.protocol : 'http:';

export const API_DOMAIN = `${PROTOCOL}//api.${HOST}`;
export const GRAPHQL_DOMAIN = `${PROTOCOL}//graphql.${HOST}`;
export const SESSION_DOMAIN = `${PROTOCOL}//session.${HOST}`;
