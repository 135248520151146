import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {BUGSNAG_PUBLIC, COMMIT_HASH} from '@halp/foundation/env';

const appVersion = COMMIT_HASH && COMMIT_HASH !== '' ? COMMIT_HASH : undefined;

Bugsnag.start({
	apiKey: BUGSNAG_PUBLIC,
	releaseStage: process.env.NODE_ENV,
	appVersion,
	enabledReleaseStages: ['production', 'staging'],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	plugins: [new BugsnagPluginReact(React as any)],
});

export default Bugsnag;
